<style lang="scss">
#debug {
  display: none;
}

#frontend {
  .kontakt-all {
    .header-intro-generel-box {
      background-position: center center !important;
    }

    .person-box {
      position: relative;
      margin-bottom: 50px;

      .txt-box {
        text-align: left;
        padding: 60px 25px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;

        .name {
          font-family: 'Helvetica Neue LT W05_75 Bold';
          font-size: 200%;
        }

        .kontakt-infos {
          margin: 10px 0;

          a {
            display: block;
            margin: 8px 0;
            padding: 3px 0;

            &:hover {
              color: #000;
            }
          }
        }

        .job {
          margin-top: 20px;
          margin-bottom: 20px;
          letter-spacing: 1px;

          strong {
            display: block;
            font-weight: normal;
            font-family: 'Helvetica Neue LT W05 65 Medium';
          }
        }

        .zitat {
          font-family: 'American Typewriter ITC W04 Md';

          margin-bottom: 40px;
          font-size: 110%;

          .zitat-icon {
            display: block;
          }
        }
      }

      .box-middle {
        border: 1px solid #878787;
      }

      .box-bottom {
        text-align: left;
        border: 1px solid #878787;
        border-top: 0;
        padding: 20px 25px;

        a {
          display: block;
          letter-spacing: 1px;
          font-size: 90%;
          position: relative;

          .icon-img {
            position: absolute;
            right: 0;
            top: 0;

            img {
              width: 30px;
              height: auto;
            }
          }

          &:hover {
            color: #000;
          }
        }
      }
    }

    .whitespace-line {
      width: 100%;
      height: 3px;
      background: $coral;
    }
  }
}
</style>

<template>
  <SocialMediaIconBox />
  <div class="kontakt-all">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="headline-box-std">
            <div class="headline-inner">
              <h1>{{ $t('General.kontakt') }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row justify-content-center align-self-center">
        <div class="col-lg-4 col-md-6 align-self-end">
          <!-- TEMPLATE -->
          <div class="person-box">
            <img src="@/assets/img/kontakt/philipp-lackner.jpg" alt="Philipp Lackner" class="img-fluid" />
            <div class="box-middle">
              <div class="txt-box">
                <div class="name">Philipp Lackner</div>
                <div class="job">
                  <strong>{{ $t('kontakt.philipp-lackner-job') }}</strong>
                  <span>Styria Media Group</span>
                </div>
                <div class="zitat">
                  <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('kontakt.philipp-lackner-zitat') }}
                </div>
              </div>
            </div>
            <div class="box-bottom">
              <a href="mailto:philipp.lackner@styria.com">philipp.lackner@styria.com<span class="icon-img"><img
                  src="@/assets/img/header-kontakt-icon.svg" alt="Kontakt" class="img-fluid" /></span></a>
            </div>
          </div>
          <!-- TEMPLATE ENDE-->
        </div>
        <div class="col-lg-4 col-md-6 align-self-end">
          <!-- TEMPLATE -->
          <div class="person-box">
            <img src="@/assets/img/kontakt/margareth-koller-prischnig.jpg" alt="Margareth Koller-Prisching "
              class="img-fluid" />
            <div class="box-middle">
              <div class="txt-box">
                <div class="name">Margareth Koller-Prisching </div>
                <div class="job">
                  <strong>{{ $t('kontakt.margareth-koller-job') }}</strong>
                  <span>Styria Media Group</span>
                </div>
                <div class="zitat">
                  <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('kontakt.margareth-koller-zitat') }}
                </div>
              </div>
            </div>
            <div class="box-bottom">
              <a href="mailto:margareth.koller@styria.com">margareth.koller@styria.com<span class="icon-img"><img
                    src="@/assets/img/header-kontakt-icon.svg" alt="Kontakt" class="img-fluid" /></span></a>
            </div>
          </div>
          <!-- TEMPLATE ENDE-->
        </div>
      </div>
      <div class="row justify-content-center align-self-center">
        <div class="col-lg-4 col-md-6 align-self-start">
          <!-- TEMPLATE -->
          <div class="person-box">
            <img src="@/assets/img/kontakt/margot-hohl.jpg" alt="Margot Hol" class="img-fluid" />
            <div class="box-middle">
              <div class="txt-box">
                <div class="name">Margot Hohl</div>
                <div class="job">
                  <strong v-html="$t('kontakt.margot-hohl-job')"></strong>
                  <span>Styria Media Group</span>
                </div>
                <div class="zitat">
                  <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('kontakt.margot-hohl-zitat') }}
                </div>
              </div>
            </div>
            <div class="box-bottom">
              <a href="mailto:margot.hohl@styria.com">margot.hohl@styria.com<span class="icon-img"><img
                  src="@/assets/img/header-kontakt-icon.svg" alt="Kontakt" class="img-fluid" /></span></a>
            </div>
          </div>
          <!-- TEMPLATE ENDE-->
        </div>
        <div class="col-lg-4 col-md-6 align-self-start">
          <!-- TEMPLATE -->
          <div class="person-box">
            <img src="@/assets/img/kontakt-headquarter-styria.jpg" alt="Styria Media Group" class="img-fluid" />
            <div class="box-middle">
              <div class="txt-box">
                <div class="name">Styria Media Group</div>
                <div class="kontakt-infos">
                  <a href="https://goo.gl/maps/HavKTUhf9HfouiWz6" target="_blanK">Gadollaplatz 1, 8010 Graz</a>
                  <a href="tel:+433168063">+43 (0) 316 8063</a>
                </div>
                <div class="zitat">
                  <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('kontakt.konzern-zitat') }}
                </div>
              </div>
            </div>
            <div class="box-bottom">
              <a href="mailto:mediagroup@styria.com">mediagroup@styria.com<span class="icon-img"><img
                    src="@/assets/img/header-kontakt-icon.svg" alt="Kontakt" class="img-fluid" /></span></a>
            </div>
          </div>
          <!-- TEMPLATE ENDE-->
        </div>
      </div>
    </div>

    <!-- -->
    <div class="container padding-tb-m">
      <div class="whitespace-line"></div>
      <div class="row justify-content-center">
        <div class="col-12 padding-tb-m">
          <h3 class="text-center" v-html="$t('General.wir-freuen-uns-auf-sie')"></h3>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-8" v-if="!sent">
            <!-- -->
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <div class="label-input">{{ $t('General.form-vorname') }}</div>
                  <input type="text" v-model="firstname" />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <div class="label-input">{{ $t('General.form-nachname') }}</div>
                  <input type="text" v-model="surname" />
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <div class="label-input">{{ $t('General.form-e-mail') }}</div>
                <input type="text" v-model="email" />
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <div class="label-input">{{ $t('General.form-wie-koennen-wir-helfen') }}</div>
                <textarea v-model="text"></textarea>
              </div>
            </div>

            <div class="col-lg-12">
              <a :href="path" target="_blank" v-html="$t('General.datenschutz-form-txt')"
                class="link-form-datenschutz"></a>
            </div>

            <div class="col-lg-4 col-md-10 margin-t-s">
              <button class="btn-formular" @click="send">{{ $t('General.btn-senden') }}</button>
            </div>
            <div class="row justify-content-center" v-if="error && error != ''">
              <div class="col-lg-12 col-md-10 text-center">
                {{ error }}
              </div>
            </div>
          </div>

          <div class="col-lg-8 text-center" v-if="sent">
            {{ $t('General.email-success') }}
          </div>
          <!-- -->
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import Footer from '../../components/Frontend/Footer.vue';
import SocialMediaIconBox from '../../components/Frontend/SocialMediaIconBox.vue';
import { mdiChevronUp, mdiFormatQuoteClose } from '@mdi/js';

import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  meta: {
    de: {
      title: 'Kontakt | Styria Media Group',
      keywords: ['kontakt', 'kontaktformular', 'email', 'mail adresse', 'kontaktieren', 'styria media group'],
      description: 'Wir freuen uns auf Sie! Mail-Adresse, Telefonnummer, Kontaktformular',
      image: require('@/assets/img/og/startseite.png'),
    },
    en: {
      title: 'Contact | Styria Media Group',
      keywords: ['contact', 'contact form', 'email', 'mail adresse', 'styria media group'],
      description: 'We look forward to hearing from you! Mail address, telephone number, contact form.',
      image: require('@/assets/img/og/startseite.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    Footer,
  },
  setup() {
    const store = useStore();
    const i18n = useI18n();
    const firstname = ref('');
    const surname = ref('');
    const text = ref('');
    const email = ref('');
    const dsvgo = ref(false);
    const error = ref('');
    const path = `${store.getters.getHomeBase}datenschutz_${i18n.locale.value}.html`;
    const sent = ref(false);

    const send = () => {
      error.value = '';

      if (!text.value || text.value == '') {
        error.value = i18n.t('General.formerror-firstname');
        return;
      }

      if (!surname.value || surname.value == '') {
        error.value = i18n.t('General.formerror-surname');
        return;
      }
      if (!email.value || email.value == '') {
        error.value = i18n.t('General.formerror-email');
        return;
      }
      if (!text.value || text.value == '') {
        error.value = i18n.t('General.formerror-text');
        return;
      }
      if (error.value == '') {
        store
          .dispatch('sendMail', {
            type: 'contact',
            locale: i18n.locale.value,
            data: {
              firstname: firstname.value,
              surname: surname.value,
              text: text.value,
              email: email.value,
            },
          })
          .then(() => {
            sent.value = true;
          });
      }
      return;
    };

    return {
      sent,
      firstname,
      surname,
      text,
      email,
      error,
      send,
      mdiChevronUp,
      mdiFormatQuoteClose,
      path,
    };
  },
});
</script>
